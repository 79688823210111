<template>
    <div class="container">
        <header class="header">
            <router-link :to="{ name: 'portcall' }" class="header__item">
                <img class="logo" src="@/assets/images/logo.png" alt="logo" />
                <img class="logo_small" src="@/assets/images/logo-small.png" alt="logo" />
            </router-link>
            <div class="header__item header__item_large">
                <header-menu />
            </div>
            <user-menu class="header__item" />
        </header>
        <div class="content">
            <transition name="expand-shrink" v-if="showSidebar">
                <div
                    class="sidebar"
                    :class="{ sidebar_open: sidebarValue, 'sidebar_static-large': !isCustomPage }"
                    v-show="sidebarValue"
                >
                    <router-view name="sidebar" />
                </div>
            </transition>
            <div
                class="blur-content"
                :class="{ 'blur-content_static-large': !isCustomPage }"
                v-show="showSidebar && sidebarValue"
            />
            <div class="main-content">
                <router-view />
            </div>
        </div>
    </div>
</template>

<script>
    import HeaderMenu from '@/components/header-components/HeaderMenu';
    import UserMenu from '@/components/header-components/UserMenu';
    import { mapGetters } from 'vuex';

    export default {
        components: { UserMenu, HeaderMenu },
        computed: {
            ...mapGetters(['sidebarValue']),
            isCustomPage() {
                return this.$route.name === 'custom';
            },
            showSidebar() {
                return ['portcall', 'quay', 'custom'].includes(this.$route.name);
            },
        },
    };
</script>

<style lang="scss" scoped>
    $header-size_small: 56px;
    $header-size: 80px;

    .header {
        background: $marine-700;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: $header-size_small;
        padding: $space-12 $space-16;
        @include media($md) {
            padding: $space-20 $space-32;
        }
        @include media($lg) {
            height: $header-size;
        }
    }

    .header__item_large {
        margin-left: $space-20;
        flex: 1 1 auto;
        @include media($lg) {
            flex: initial;
        }
    }

    .logo {
        display: none;
        @include media($md) {
            display: block;
            height: 25px;
        }
        @include media($xlg) {
            height: 32px;
        }
    }

    .logo_small {
        display: block;
        height: 32px;
        @include media($md) {
            display: none;
        }
    }

    .content {
        display: flex;
    }

    .sidebar {
        background: $marine-800;
        // $header-size_small = 56
        width: 0;
        min-height: calc(100vh - 56px);
        z-index: 2;
        position: absolute;
    }

    .sidebar_static-large {
        @include media($lg) {
            position: static;
        }
        @include media($lg) {
            // $header-size = 80
            min-height: calc(100vh - 80px);
        }
    }

    .sidebar_open {
        width: 348px;
        min-width: 348px;
    }

    .blur-content {
        z-index: 1;
        width: 100%;
        height: 100%;
        position: fixed;
        right: 0;
        background: rgba($marine-900, 0.6);
    }

    .blur-content_static-large {
        @include media($lg) {
            display: none;
        }
    }

    .main-content {
        flex: 1 1 auto;
    }
</style>
